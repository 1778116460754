const question = document.querySelectorAll('.accordion-faqs-question')
const article  = document.querySelectorAll('.accordion-faqs-item')

question.forEach((eachQuestion, i) => {
    question[i].addEventListener('click', () => {
        article.forEach((eachArticle, i) => {
            article[i].classList.remove('active')
        })
        article[i].classList.add('active')
    })
})
